<template>
  <flex-row class="introduction-image" align-h="end">
    <svg width="481" height="585" viewBox="0 0 481 585" fill="none" xmlns="http://www.w3.org/2000/svg" opacity="0.7">
      <path d="M331.08 441.239C359.995 441.239 383.434 417.83 383.434 388.955C383.434 360.079 359.995 336.671 331.08 336.671C302.166 336.671 278.726 360.079 278.726 388.955C278.726 417.83 302.166 441.239 331.08 441.239Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M353.764 435.36C345.444 440.69 335.476 443.826 324.879 443.826C295.052 443.826 270.798 419.291 270.798 389.033C270.798 358.776 294.974 334.241 324.879 334.241C333.592 334.241 341.833 336.358 349.055 340.042" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M287.517 383.86L291.441 396.01" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M295.602 399.302H299.526" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M303.058 394.442L305.57 386.603" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M302.666 373.669L306.198 380.332" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M297.564 370.299H300.704" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M294.032 371.475L289.479 379.157" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M289.479 406.984L305.413 407.376" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M317.736 387.779L312.32 402.437" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M316.402 371.318L318.521 382.292" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M309.102 357.6L312.32 365.047" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M294.032 358.149L304.393 357.365" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M304.314 350.937L310.672 351.721" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M310.672 346.626L324.644 348.586" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M335.24 342.315L347.563 348.742" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M355.256 359.56L357.767 378.921" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M359.573 394.05L352.273 413.412" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M344.659 423.21L335.79 429.873" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M313.262 432.224L320.091 429.324" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M328.176 423.837L336.574 413.804" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M338.929 401.105L342.854 386.76" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M337.359 355.641L341.362 372.102" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M326.37 355.797L331.08 375.316" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M317.658 354.622L320.483 363.871" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M321.504 369.201L323.466 375.316" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M325.821 388.171L316.637 407.533" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M334.22 385.036L331.08 400.713" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M325.193 409.1L319.071 416.155" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M312.32 420.701L300.704 423.445" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M293.796 415.763L308.317 412.628" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M379.196 361.285H381.551" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M383.434 357.757V348.978L427.233 332.125" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M389.164 357.757L430.608 343.491" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M434.925 347.567L441.205 370.299" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M438.065 373.434L398.426 388.955" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M391.519 381.195L398.426 388.955" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M396.072 375.316L433.12 363.871" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M440.969 346.94L437.358 348.429" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M439.478 356.895L443.559 354.778" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M446.464 354.465L448.426 353.289L450.31 358.227L456.511 355.484L449.054 335.809L442.46 338.16L444.501 342.864L442.46 344.039L446.464 354.465Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M388.772 351.486L390.185 352.976" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M393.638 349.135L395.051 350.624" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M398.505 347.724L399.918 349.135" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M403.371 345.372L404.784 346.783" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M413.104 342.001L414.517 343.491" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M408.238 343.491L409.651 344.902" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M422.837 338.474L424.25 339.963" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M417.971 340.198L419.384 341.688" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M427.704 336.671L429.117 338.16" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M397.606 273.071L387.777 275.81L392.356 292.194L402.184 289.455L397.606 273.071Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M398.819 277.411L405.334 274.824" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M400.546 283.76L407.06 281.33" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M321.292 253.386L273.848 273.569L289.751 310.853L337.195 290.67L321.292 253.386Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M291.755 313.704L302.587 339.101" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M305.491 308.608L316.716 334.241" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M290.5 321.385L297.171 336.671" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M290.578 300.848L328.804 284.858" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M297.48 268.125L279.211 275.49L280.621 278.979L298.89 271.614L297.48 268.125Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M319.534 259.028L301.265 266.393L302.675 269.882L320.945 262.517L319.534 259.028Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M289.508 281.014L283.685 283.361L288.532 295.355L294.355 293.008L289.508 281.014Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M300.101 276.752L294.278 279.099L299.126 291.093L304.948 288.746L300.101 276.752Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M310.837 272.373L305.015 274.72L309.862 286.713L315.685 284.366L310.837 272.373Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M321.452 268.144L315.629 270.491L320.477 282.484L326.3 280.137L321.452 268.144Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M261.85 370.299L251.018 340.747L236.889 343.099L210.83 352.897L199.841 359.246L209.81 390.523L221.191 381.273L247.093 372.258H258.475" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M221.191 381.273L210.83 352.897" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M247.093 372.258L236.889 343.099" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M209.81 390.523L253.765 375.316" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M228.805 383.938L238.381 409.1" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M232.808 382.919L241.991 407.768" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M239.558 380.254L249.684 404.946" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M243.169 378.922L253.059 403.535" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M268.208 397.813L222.604 416.077" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M276.842 414.196L228.726 433.792" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M229.119 419.996L231.787 426.581" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M233.043 417.174L235.947 423.994" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M239.637 415.058L242.305 421.642" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M243.561 412.314L246.387 419.134" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M251.41 410.746L254.079 417.331" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M257.14 407.219L260.045 413.96" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M263.969 405.024L266.873 411.844" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M218.915 359.952L219.699 362.774" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M223.86 358.541L224.645 361.285" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M228.491 356.503L229.276 359.246" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M233.593 355.014L234.378 357.757" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M222.368 369.437L223.153 372.18" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M227.392 368.026L228.177 370.769" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M231.944 365.909L232.729 368.653" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M237.125 364.42L237.91 367.163" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M208.711 359.56L204.629 361.99L206.199 367.085L210.438 365.047L208.711 359.56Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M207.141 372.494L212.949 369.045" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M209.26 375.708L211.144 382.371L216.011 378.53L213.97 372.807L209.26 375.708Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M249.605 367.085H255.492L253.53 360.579H247.643L249.605 367.085Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M245.209 356.816H253.53" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M241.991 347.096L243.483 352.113H250.861L248.899 346.077L241.991 346.548" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M214.912 397.813L234.456 450.88L230.845 452.213L211.222 397.813H214.912Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M207.533 397.813L131.317 426.502C131.317 426.502 133.986 443.59 138.225 455.897C141.521 465.695 150.783 482.941 150.783 482.941L230.453 454.878" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M137.989 440.455L209.81 414.196" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M148.036 465.539L220.249 440.455" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M178.884 473.064L188.617 501.597" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M167.188 477.218L176.686 505.516" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M173.939 507.084L163.499 478.473" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M134.3 427.286L138.774 428.148L141.129 435.595" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M142.934 427.286L160.752 420.623L162.165 425.013L144.661 431.441L142.934 427.286Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M164.755 419.055L166.089 423.288L180.924 418.35L179.669 414.039L164.755 419.055Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M200.312 406.357L183.986 412.079L185.241 417.096L202.196 411.217L200.312 406.357Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M209.025 401.418L204.158 403.535L206.591 410.746L211.223 409.022" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M148.272 469.693L152.51 468.596L154.944 475.651L151.019 476.905" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M157.063 468.753L174.881 462.011L176.293 466.401L158.79 472.829L157.063 468.753Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M178.884 460.444L180.218 464.676L195.053 459.816L193.797 455.427L178.884 460.444Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M214.441 447.745L198.114 453.545L199.37 458.484L216.324 452.605L214.441 447.745Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M228.098 452.213L220.798 451.586L218.287 445.393L223.781 443.826" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M131.317 375.316L146.231 420.937" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M139.952 372.807L154.944 417.645" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M128.021 376.883L142.306 421.799" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M144.74 363.322L119.622 374.061L125.274 377.589L155.336 364.655L144.74 363.322Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M155.336 364.655L140.658 322.875L130.218 317.623L103.139 328.519L119.622 374.061" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M144.74 361.99L132.809 325.775L130.219 317.623" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M131.385 363.854C135.727 362.353 138.833 359.943 138.323 358.47C137.813 356.998 133.879 357.02 129.537 358.521C125.194 360.021 122.088 362.432 122.598 363.904C123.108 365.377 127.042 365.355 131.385 363.854Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M126.988 356.008C131.33 354.508 134.437 352.097 133.926 350.624C133.416 349.152 129.482 349.174 125.14 350.675C120.797 352.175 117.691 354.586 118.201 356.058C118.711 357.531 122.645 357.509 126.988 356.008Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M123.781 346.758C128.123 345.257 131.23 342.847 130.72 341.374C130.209 339.901 126.275 339.924 121.933 341.424C117.591 342.925 114.484 345.335 114.995 346.808C115.505 348.281 119.439 348.258 123.781 346.758Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M110.203 347.959L92.3069 354.23" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M112.087 353.367L94.5045 359.56" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M88.7746 347.959L96.2314 365.596L88.7746 367.085L82.7307 352.035L88.7746 347.959Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M49.921 326.402L58.9476 347.567" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M194.19 584.451C217.079 584.451 235.634 565.921 235.634 543.063C235.634 520.205 217.079 501.675 194.19 501.675C171.301 501.675 152.746 520.205 152.746 543.063C152.746 565.921 171.301 584.451 194.19 584.451Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M177.863 534.362L170.171 544.161L171.898 556.703" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M174.017 559.211L180.924 564.149" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M184.928 564.541L195.681 562.738" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M198.036 559.211L202.981 547.296" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M195.681 534.362L202.196 544.552" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M182.337 532.403L193.169 532.011" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M182.18 522.212H191.756" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M202.588 528.248L209.26 539.458" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M212.007 547.296L207.69 560.23" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M202.981 567.677L191.756 572.772" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M216.717 563.601L212.007 572.772" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M223.781 537.263L223.153 550.432" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M209.81 518.842L218.051 528.483" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M333.435 482.235L330.138 473.221" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M325.978 473.77L281.081 491.25" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M277.705 493.366L281.865 505.359" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M285.005 514.374L289.322 525.74" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M291.834 524.329L338.772 506.692" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M341.912 505.124L338.929 494.934" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M288.694 512.963L336.025 495.953" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M284.22 504.419L333.042 486.154" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M283.043 495.326L284.299 499.323L298.113 493.444L296.779 489.917L283.043 495.326Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M300.86 488.663L301.881 491.72L310.201 488.428L309.338 485.135L300.86 488.663Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M312.634 483.333L313.89 487.409L327.705 481.53L326.37 478.002L312.634 483.333Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M297.093 522.369L295.209 516.961L333.121 503.165L334.848 508.181" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M283.043 508.338L284.22 511.473" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M333.67 489.368L334.848 492.504" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M325.429 493.052L326.606 496.188" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M316.559 495.639L317.736 498.775" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M308.631 498.932L309.809 502.067" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M298.741 502.381L299.919 505.516" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M290.892 505.438L292.069 508.573" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M340.97 492.504L358.081 486.781L367.265 514.295" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M354.942 487.879L364.832 515.55" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M337.673 480.354L350.939 475.337L355.256 477.689L336.417 484.352" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M349.997 472.672L340.421 445.472" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M342.854 442.807L355.256 477.689" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M353.058 439.514L364.675 472.123" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M383.984 464.441L364.675 472.123" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M379.117 512.101L369.62 483.568" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M381.393 559.76C394.138 559.76 404.47 549.442 404.47 536.714C404.47 523.986 394.138 513.668 381.393 513.668C368.648 513.668 358.317 523.986 358.317 536.714C358.317 549.442 368.648 559.76 381.393 559.76Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M387.908 558.584C384.69 560.23 381.079 561.249 377.233 561.249C364.125 561.249 353.45 550.275 353.45 536.714C353.45 523.153 364.125 512.179 377.233 512.179C381.001 512.179 384.612 513.12 387.83 514.766" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M387.908 532.481L381.393 529.267L374.957 535.224L376.292 543.063L383.591 546.355L389.792 541.26L387.908 532.481Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M373.78 534.754L369.384 531.384" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M377.626 523.388L369.934 529.267" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M368.285 533.03L369.855 545.807" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M374.957 543.926L371.582 547.453" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M383.513 552.47L371.582 547.453" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M384.298 548.08L384.926 551.451" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M387.202 551.294L395.679 544.161" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M389.792 541.26L395.679 544.161" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M387.908 532.481L391.44 528.875L396.935 534.206L395.679 544.161" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M381.393 529.267L380.844 522.761L389.792 526.289" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M382.021 461.228L381.315 449.078C381.315 449.078 391.126 467.342 394.031 478.238C396.935 489.055 399.29 502.929 399.29 502.929L386.574 477.689L369.62 483.568" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M381.393 447.51L387.987 444.609C387.987 444.609 398.034 457.308 400.546 465.225" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M418.913 435.673C418.913 435.673 425.977 450.724 430.294 462.56C434.611 474.475 435.161 488.976 435.161 488.976L443.245 485.37C443.245 485.37 442.696 470.869 438.379 458.954C434.062 447.039 426.997 432.068 426.997 432.068L420.561 434.027" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M402.429 466.48L425.114 458.092" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M436.024 452.683L460.514 443.512" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M465.223 444.61L460.514 443.512" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M405.491 479.57L431.942 470.007" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M406.746 484.352L432.57 474.788" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M442.382 470.399L466.557 460.992" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M472.287 474.788L466.557 460.992" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M402.037 502.929L407.924 498.853C408.552 493.523 405.412 480.981 405.412 480.981" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M474.799 473.534L480.451 471.183C480.451 471.183 479.901 456.681 475.584 444.766C471.267 432.852 464.203 417.88 464.203 417.88L456.118 421.485C456.118 421.485 463.182 436.536 467.499 448.372C471.816 460.208 472.366 474.788 472.366 474.788" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M391.755 444.061L412.947 436.536" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M398.269 450.253L415.694 444.061" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M402.351 456.916L417.107 451.429" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M430.451 430.265L452.586 422.583" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M434.768 437.476L453.842 431.049" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M438.928 443.512L454.47 438.104" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M442.382 465.304L464.438 457.151" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M409.415 196.829L403.842 178.8" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M399.211 178.8L375.899 187.815L384.298 209.136L408.081 201.297" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M383.277 211.174L378.803 219.64L375.899 212.193L377.39 207.49L375.899 187.815" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M383.277 217.445L403.842 211.174" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M406.589 209.136L409.415 203.727" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M272.289 158.184L278.019 174.646" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M374.329 109.82L385.083 132.552" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M315.695 109.036L309.73 90.772L303.137 93.1236L309.416 112.72L313.341 111.153" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M116.405 130.024L108.594 133.107L114.739 148.634L122.549 145.552L116.405 130.024Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M88.8531 178.016L95.1325 193.929" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M142.777 251.7C144.381 251.7 145.681 250.401 145.681 248.799C145.681 247.198 144.381 245.899 142.777 245.899C141.173 245.899 139.873 247.198 139.873 248.799C139.873 250.401 141.173 251.7 142.777 251.7Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M45.6039 77.9165C47.6847 77.9165 49.3716 76.2319 49.3716 74.1539C49.3716 72.0759 47.6847 70.3914 45.6039 70.3914C43.5231 70.3914 41.8363 72.0759 41.8363 74.1539C41.8363 76.2319 43.5231 77.9165 45.6039 77.9165Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M0.62793 70.3914L11.4598 97.5132" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M168.444 0.627197L176.293 21.0077" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M341.362 28.6113C342.966 28.6113 344.267 27.3128 344.267 25.711C344.267 24.1092 342.966 22.8107 341.362 22.8107C339.759 22.8107 338.458 24.1092 338.458 25.711C338.458 27.3128 339.759 28.6113 341.362 28.6113Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M196.701 247.781L201.646 263.223L212.871 258.52L207.69 243.469L196.701 247.781Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M204.786 241.823L202.196 240.49L190.03 245.742L194.19 259.147L201.646 263.223" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M196.701 247.78L190.03 245.742" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M205.598 105.713L184.479 113.079L197.936 151.561L219.055 144.196L205.598 105.713Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M205.65 105.744H196.544L178.727 111.936L192.855 149.797L197.957 151.6" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M184.535 113.112L178.727 111.936" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M189.637 114.993L190.814 118.051L203.373 113.582L201.803 110.447L189.637 114.993Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M191.05 121.578L209.103 115.699" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M198.742 141.802L199.92 144.859L212.478 140.391L210.908 137.255L198.742 141.802Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M193.483 138.823L201.803 134.276L197.643 120.088" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M200.861 118.364L205.649 133.257L214.127 131.611" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </flex-row>
</template>

<script>
export default {
  name: 'IntroductionSecondSvg'
}
</script>

<style scoped lang="scss">
  @media (max-width: $screen-sm-max) {
    .introduction-image svg{
      height: unset;
      max-width: 55%;
      margin-left: auto;
      position: absolute;
      bottom: 48px;
    }
  }
</style>
