<template>
  <div class="icon-link" @click="$emit('click')">
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.01607 5.94373V11.8788C3.01607 12.5827 3.58547 13.1518 4.28907 13.1518H10.2241C10.928 13.1518 11.4971 12.5824 11.4971 11.8788V5.94373C11.4971 5.23989 10.9277 4.67074 10.2241 4.67074H4.28907C3.58522 4.67074 3.01607 5.24013 3.01607 5.94373ZM2.16797 5.94373C2.16797 4.77228 3.11628 3.82263 4.28907 3.82263H10.2241C11.3956 3.82263 12.3452 4.77095 12.3452 5.94373V11.8788C12.3452 13.0503 11.3969 13.9999 10.2241 13.9999H4.28907C3.11762 13.9999 2.16797 13.0516 2.16797 11.8788V5.94373Z" fill="white"/>
      <path d="M7.55678 9.21123L14.1296 2.63841C14.2952 2.4728 14.2952 2.20431 14.1296 2.0387C13.964 1.8731 13.6955 1.8731 13.5299 2.0387L6.95708 8.61153C6.79147 8.77713 6.79147 9.04562 6.95708 9.21123C7.12268 9.37683 7.39118 9.37683 7.55678 9.21123Z" fill="white"/>
      <path d="M13.6177 5.09471C13.6177 5.32891 13.8075 5.51877 14.0417 5.51877C14.2759 5.51877 14.4658 5.32891 14.4658 5.09471L14.4658 2.14118C14.4658 1.90781 14.2772 1.7183 14.0438 1.71714L11.0755 1.70229C10.8413 1.70112 10.6505 1.89003 10.6493 2.12422C10.6481 2.35842 10.837 2.54922 11.0712 2.55039L13.6177 2.56312L13.6177 5.09471Z" fill="white"/>
    </svg>
  </div>

</template>

<script>
export default {
  name: 'LinkIcon'
}
</script>

<style scoped>

  .icon-link {
    cursor: pointer;
    margin: 0 0.375rem;
    line-height: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 3px;
  }

</style>
