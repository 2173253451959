<template>
  <div>
    <flex-row align-v="center">
      <svg width="16" height="16" opacity="0.7" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7996 9.99999V5.09839L7.40281 7.94479C7.34134 7.98094 7.27132 8.00001 7.20001 8.00001C7.1287 8.00001 7.05868 7.98094 6.99721 7.94479L1.59961 5.09839V9.99999C1.59961 10.5304 1.81032 11.0391 2.1854 11.4142C2.56047 11.7893 3.06918 12 3.59961 12H10.7996C11.33 12 11.8387 11.7893 12.2138 11.4142C12.5889 11.0391 12.7996 10.5304 12.7996 9.99999Z" fill="white"/>
        <path d="M12.7873 4.17842C12.7328 3.68934 12.4999 3.23752 12.1331 2.90942C11.7663 2.58131 11.2914 2.39995 10.7993 2.40002H3.59933C3.10721 2.39995 2.63234 2.58131 2.26556 2.90942C1.89879 3.23752 1.66585 3.68934 1.61133 4.17842L7.19933 7.13602L12.7873 4.17842Z" fill="white"/>
        <path opacity="0.5" d="M5.19961 13.6C4.88908 13.6003 4.58276 13.5281 4.30501 13.3892C4.02726 13.2503 3.78573 13.0486 3.59961 12.8H10.7996C11.5422 12.8 12.2544 12.505 12.7795 11.9799C13.3046 11.4548 13.5996 10.7426 13.5996 10V4.40002C14.0852 4.76482 14.3996 5.34562 14.3996 6.00002V10C14.3996 10.9548 14.0203 11.8705 13.3452 12.5456C12.6701 13.2207 11.7544 13.6 10.7996 13.6H5.19961Z" fill="white"/>
      </svg>
      <span class="label">{{label}}</span>
    </flex-row>
    <div class="email">{{email}}</div>
  </div>

</template>

<script>
export default {
  name: 'EmailContact',
  props: {
    label: String,
    email: String
  }
}
</script>

<style scoped lang="scss">

  .label {
    opacity: 0.7;
    font-size: 0.875rem;
    line-height: 1.4em;
    letter-spacing: -0.02em;
    text-shadow: $heading-shadow-base;
  }
  .email {
    line-height: 1.6em;
  }

</style>
