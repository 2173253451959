<template>
  <svg width="352" height="502" viewBox="0 0 352 502" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M173.94 227.941L229.891 126.636C229.891 126.636 242.268 104.679 255.747 93.3195C268.379 82.5531 279.823 78.3992 283.384 78.3992C286.181 78.3992 288.555 80.1794 289.572 82.0445C291.692 85.8593 292.37 93.5738 291.861 104.001C291.437 112.902 288.216 134.011 279.484 149.525L224.805 255.917C224.805 255.917 214.208 252.526 213.784 250.831L262.529 126.636L187.504 233.452C187.504 233.452 177.755 231.332 173.94 227.941Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M272.448 84.7572L288.216 93.2347" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M249.559 102.984C249.559 102.984 258.629 103.238 269.65 108.748C280.671 114.259 285.503 121.634 285.503 121.634" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M242.438 112.309C242.438 112.309 252.526 112.648 264.818 118.752C277.111 124.94 282.452 133.079 282.452 133.079" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M270.752 93.1499L271.854 92.1326" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M266.768 90.5219L267.955 89.5046" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M275.076 95.8627L275.839 94.5063" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M279.145 98.0668L279.908 96.7952" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M283.384 100.271L283.808 98.8298" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M287.453 102.56L287.707 101.034" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M263.462 100.101L264.564 99.0841" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M258.714 98.0669L259.901 97.0496" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M268.379 102.051L269.396 100.864" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M277.026 107.053L277.789 105.697" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M281.773 110.105L282.197 108.664" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M286.351 112.648L286.521 111.122" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M272.448 104.51L273.211 103.153" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M187.504 233.452L209.969 216.497L252.356 144.862" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M247.1 159.698L217.598 219.633L209.969 216.497" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M217.599 219.633L213.784 250.83" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M175.212 230.23L172.668 237.436C172.668 237.436 212.936 256.256 218.192 261.512L224.805 255.917" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M172.668 239.386V244.303C172.668 244.303 207.002 262.275 212.258 266.768L217.175 263.801" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M201.915 160.969L142.997 258.206L138.334 241.251L201.915 160.969Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M281.604 155.459L230.146 257.189L245.151 253.035L281.604 155.459Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M203.781 264.225C203.781 264.225 195.557 269.735 192.167 279.484C192.167 279.484 197.507 287.284 205.561 288.809C205.561 288.809 211.749 281.01 210.647 269.481" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M181.061 248.711C181.061 248.711 169.108 256.341 166.564 266.683C166.564 266.683 171.481 273.804 180.298 275.076C180.298 275.076 188.182 269.65 187.504 253.035" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M172.669 244.218C172.669 244.218 161.054 252.017 158.935 259.986L163.852 264.225" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M181.485 290.166C180.128 290.59 169.701 298.05 166.564 305.595C166.564 305.595 171.227 313.903 181.4 315.513C181.4 315.513 190.471 303.73 187.843 292.455C187.843 292.455 186.063 288.725 181.485 290.166Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M141.132 284.486L130.959 300.169L136.046 303.391L144.947 287.284L141.132 284.486Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <g opacity="0.3">
      <path d="M238.199 1L204.289 54.6623" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
      <path d="M240.488 41.6918L182.248 135.537" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M119.515 107.223L82.2139 165.463" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M159.104 123.669L138.165 156.392" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M332.044 53.5602L314.496 82.3835" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M314.496 129.264L301.525 151.305" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M339.929 130.959L283.978 230.993" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M350.695 202.763L329.247 241.166" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M303.814 252.526L282.282 289.233" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M323.313 308.986L274.991 388.165" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M268.209 337.809L207.68 433.943" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M119.514 232.689L65.2588 320.346" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M97.4733 205.561L57.375 270.837" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M163.598 177.924L144.947 207.85" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M192.167 323.313L172.668 356.799" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <path d="M212.767 325.602L201.916 341.709L205.73 351.034L212.767 325.602Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M225.822 296.948C227.788 296.948 229.382 295.354 229.382 293.387C229.382 291.421 227.788 289.827 225.822 289.827C223.855 289.827 222.261 291.421 222.261 293.387C222.261 295.354 223.855 296.948 225.822 296.948Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M153.085 307.206C154.911 307.206 156.392 305.725 156.392 303.899C156.392 302.073 154.911 300.593 153.085 300.593C151.26 300.593 149.779 302.073 149.779 303.899C149.779 305.725 151.26 307.206 153.085 307.206Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M108.579 316.531C112.98 316.531 116.547 312.963 116.547 308.562C116.547 304.161 112.98 300.593 108.579 300.593C104.178 300.593 100.61 304.161 100.61 308.562C100.61 312.963 104.178 316.531 108.579 316.531Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M151.39 348.575C157.43 348.575 162.326 343.679 162.326 337.64C162.326 331.6 157.43 326.704 151.39 326.704C145.35 326.704 140.454 331.6 140.454 337.64C140.454 343.679 145.35 348.575 151.39 348.575Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M187.928 370.786C190.971 370.786 193.438 368.319 193.438 365.276C193.438 362.233 190.971 359.766 187.928 359.766C184.885 359.766 182.417 362.233 182.417 365.276C182.417 368.319 184.885 370.786 187.928 370.786Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M196.405 396.388C198.044 396.388 199.372 395.06 199.372 393.421C199.372 391.783 198.044 390.454 196.405 390.454C194.766 390.454 193.438 391.783 193.438 393.421C193.438 395.06 194.766 396.388 196.405 396.388Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M98.4906 363.157C100.363 363.157 101.882 361.638 101.882 359.766C101.882 357.893 100.363 356.375 98.4906 356.375C96.6178 356.375 95.0996 357.893 95.0996 359.766C95.0996 361.638 96.6178 363.157 98.4906 363.157Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M76.7886 334.588C78.6146 334.588 80.0948 333.107 80.0948 331.281C80.0948 329.455 78.6146 327.975 76.7886 327.975C74.9627 327.975 73.4824 329.455 73.4824 331.281C73.4824 333.107 74.9627 334.588 76.7886 334.588Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M155.12 393.252C161.113 393.252 165.971 388.393 165.971 382.4C165.971 376.408 161.113 371.549 155.12 371.549C149.127 371.549 144.269 376.408 144.269 382.4C144.269 388.393 149.127 393.252 155.12 393.252Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M72.0412 404.951C78.5023 404.951 83.74 399.713 83.74 393.252C83.74 386.79 78.5023 381.553 72.0412 381.553C65.58 381.553 60.3423 386.79 60.3423 393.252C60.3423 399.713 65.58 404.951 72.0412 404.951Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M120.871 398.169C124.804 398.169 127.992 394.98 127.992 391.047C127.992 387.115 124.804 383.926 120.871 383.926C116.938 383.926 113.75 387.115 113.75 391.047C113.75 394.98 116.938 398.169 120.871 398.169Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M109.257 415.802C110.615 415.802 111.716 414.701 111.716 413.343C111.716 411.985 110.615 410.885 109.257 410.885C107.9 410.885 106.799 411.985 106.799 413.343C106.799 414.701 107.9 415.802 109.257 415.802Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M158.511 458.952C160.384 458.952 161.902 457.434 161.902 455.561C161.902 453.688 160.384 452.17 158.511 452.17C156.638 452.17 155.12 453.688 155.12 455.561C155.12 457.434 156.638 458.952 158.511 458.952Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.37538 434.452C12.4487 434.452 15.7508 431.15 15.7508 427.077C15.7508 423.003 12.4487 419.701 8.37538 419.701C4.30207 419.701 1 423.003 1 427.077C1 431.15 4.30207 434.452 8.37538 434.452Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M98.8298 485.232C101.218 485.232 103.153 483.296 103.153 480.908C103.153 478.521 101.218 476.585 98.8298 476.585C96.442 476.585 94.5063 478.521 94.5063 480.908C94.5063 483.296 96.442 485.232 98.8298 485.232Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M149.44 501C156.932 501 163.004 494.927 163.004 487.436C163.004 479.945 156.932 473.872 149.44 473.872C141.949 473.872 135.876 479.945 135.876 487.436C135.876 494.927 141.949 501 149.44 501Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M45.7608 359.766L34.0619 379.688L30.2471 370.786L45.7608 359.766Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M114.598 338.318L106.799 346.795L112.903 351.034L114.598 338.318Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M106.799 346.795L112.055 360.444L112.903 351.034L120.108 348.575L114.598 338.318" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M112.055 360.444L120.108 348.575" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M147.236 331.875L148.084 344.676C148.084 344.676 155.883 343.998 156.307 338.318C156.731 332.638 147.236 331.875 147.236 331.875Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M144.862 336.453L158.85 336.114" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M144.269 340.013H158.681" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M156.718 386.441C157.252 385.041 156.161 383.325 154.28 382.606C152.399 381.888 150.442 382.441 149.907 383.84C149.373 385.24 150.464 386.957 152.345 387.675C154.226 388.393 156.184 387.841 156.718 386.441Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M153.17 377.568L152.492 382.316" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M159.274 380.366L156.307 383.926" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M66.9546 388.25L73.8213 385.283L77.5514 391.047" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M66.2764 396.388L70.0912 401.729L76.195 399.101" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M65.5981 391.047L66.6154 393.252" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M71.1934 392.15L72.2954 394.862" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M77.1274 393.76L77.9752 395.964" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M82.3836 426.144L74.0757 439.708L77.5514 442.675L81.7054 435.724L84.2486 436.571L88.4873 430.213L82.3836 426.144Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M121.634 454.374V466.158L133.079 461.919V450.474L121.634 454.374Z" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M133.079 461.919L121.634 454.374" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M53.3058 455.561L43.6415 450.474L30.9253 470.651L39.5723 477.433L53.3058 455.561Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43.3872 454.374L48.0498 457.511" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M35.5034 469.125L39.403 472.346" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M43.8111 460.647C43.8111 460.647 40.8439 459.121 40.1658 461.241C39.4876 463.191 41.0983 463.36 42.8785 463.954C44.574 464.547 44.6588 468.531 40.081 467.175" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M180.129 407.409L170.464 402.323L157.748 422.499L166.395 429.281L180.129 407.409Z" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M170.21 406.307L174.873 409.359" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M162.326 420.973L166.225 424.194" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M170.634 412.495C170.634 412.495 167.667 410.97 166.989 413.089C166.31 415.039 167.921 415.208 169.701 415.802C171.397 416.395 171.482 420.379 166.904 419.023" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M149.779 480.654C149.779 480.654 143.675 488.03 143.421 491.082C143.252 492.438 146.219 493.54 146.219 493.54" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M153.085 484.554C152.831 485.232 152.831 485.232 152.831 485.232" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M149.864 494.388C149.864 494.388 155.629 487.606 156.138 484.723C156.392 483.367 153.425 481.587 153.425 481.587" stroke="#FFC22E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'MarsMarketsSvg'
}
</script>

<style scoped>

</style>
