import { render, staticRenderFns } from "./JourneyStage.vue?vue&type=template&id=255c09b2&scoped=true&"
import script from "./JourneyStage.vue?vue&type=script&lang=js&"
export * from "./JourneyStage.vue?vue&type=script&lang=js&"
import style0 from "./JourneyStage.vue?vue&type=style&index=0&id=255c09b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255c09b2",
  null
  
)

export default component.exports